import React, { useState, useEffect } from 'react';
import { Row } from 'antd'
import Footer from '../components/Footer'
import { Typography } from '@mui/material';
import "../styles/privacy.css"

const PrivacyPolicy = () => {

  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const cssStyle = {
    logo: {
      marginTop: "8px",
      height: "32px",
      width: "108px",
    },
    logo2: {
      height: "28px",
      width: "32px",
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: '24px',
    }
  };

  return (
    <div>
      <div className="privacy-policy-terms">

        <Row style={cssStyle.logoContainer}>
          <img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo2} />
          <div style={{ width: '8px' }} />
          <img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} />
        </Row>

        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <p>Updated on: 2024-01-19</p>
        <p>This policy outlines how <strong>NeoFirst Technologies India Pvt Ltd</strong>, the developer of the app <strong>"DailyPe"</strong> on Play Store collects, uses, and safeguards your personal information while using the DailyPe app. We prioritize the privacy and security of our users and aim to provide transparency regarding the data we collect and how it is utilized. Please read this Privacy Policy carefully to understand how your information is handled within the DailyPe app ecosystem.</p>

        <Typography variant="h5" gutterBottom>1. Collection of Personally Identifiable Information and other Information</Typography>
        <p>When Registering on our app, You will be asked to enter your name, Address, Mobile No, income details etc where our goal is to protect customer's data and to provide safe, secure and customized experience. We promise to keep all information about our Customers totally private. When you create an account on our Application, we collect and store your information and automatically track your information with the help of "Cookies". Cookies is a small file stored on customer's hard drive and help the organization to track customer's activities. We use this information so that we understand and serve our customers in a better way.</p>

        <Typography variant="h5" gutterBottom>2. Use of your data</Typography>
        <p>We collect and use your personal information to operate our application and deliver the Services you have requested. When you choose to borrow through DailyPe, we record and analyze each customer’s transaction history. Our purpose of using customer's personal information is to inform them about online and offline offers, products, services and updates. It also helps us to detect and protect us against fraud or other criminal activity. We recognize and utilize your IP address to help determine issues to have on our server, and to control our Website. <br /><br />For more information on the specifics of kinds of data collected, refer the <a href="#permissions"> Permissions section</a>.</p>

        <Typography variant="h5" gutterBottom>3. Data We Share</Typography>
        <p>We keep your personal information confidential and do not sell your personal data to third parties. DailyPe values your privacy. We may share your information with payment gateway providers, Customer Service, and support providers as well as those who assist us in providing Advertising Services. Third party uses personal data of yours, so that you access to our services and helps us to investigate fraudulent or illegal activities. Without the consent of our customer, we do not share personal information to the third parties for their marketing or advertising purposes.</p>

        <Typography variant="h5" gutterBottom>4. Connections to Other Sites</Typography>
        <p>We may include third party products or services on our Application. These third party sites have separate and independent privacy policies. We encourage you to carefully read the privacy policy of any website you visit. These companies may use information (including your name, address, email address, or telephone number) about your visits to their site when you try to buy any product or fetch any details of the product. Therefore, we do not take any responsibility for the content and the activities of these linked sites. We would also like to inform you that any personal data you provide to Payment Gateway Providers will be taken care by Payment gateway providers only as they have their own privacy policy. We cannot accept the liablity for the actions and also we are not responsible for the content or privacy practices of such sites.</p>

        <p><strong>Note:</strong> You should be cautious before giving third-party applications access to use your account. Before using the link of any website on our Application, make sure that the website is genuine and has the best Internet security so that you can protect your PC/Mobile from harmful threats.</p>

        <Typography variant="h5" gutterBottom>5. Manner Of Collection Of Information</Typography>
        <p>Your information might be gathered:-</p>
        <ul>
          <li>When you enroll with the Company by setting up your account;</li>
          <li>Through your Transactions with any of the technology partners;</li>
          <li>Directly through your input;</li>
          <li>Through your use of the Services;</li>
          <li>Through the use of cookies and other tracking technologies;</li>
          <li>Through access which will be provided by you to DailyPe Application on your mobile phone</li>
        </ul>
        <p>Payment gateway providers tracks user activity, page content, click/touch, movement, scroll on the website. DailyPe never stores or shares personal information of the customer with third party tools.</p>
        <Typography variant="h5" gutterBottom>6. Security Precautions</Typography>
        <p>The security of your information is important to us. We maintain the information you provide and safely secure the information in the company's databases and these databases are stored on secure servers with appropriate firewalls. When you enter sensitive information, we encrypt that information using secure socket layer technology. We never permanently store complete credit card/debit card details. We follow some industry standards to protect the personal information submitted to us, both during transmission and once we receive it. If you find out any unauthorized use of your account or any suspicious activity, we request you to notify DailyPe Team immediately either by sending an email to help@dailype.in.</p>
        <p>Note :- We urge you to take steps to keep your personal information safe so always choose a strong password and keep it private.</p>
        <Typography variant="h5" gutterBottom>7. Accessing and Updating Your Information</Typography>
        <p>DailyPe gives you the ability to access and update certain information about you from within your account at any time if you believe that the information entered by you at the time of registration is incorrect or should be changed due to some reasons, you have the right to contact us. You may likewise change as well as erase any of the data you have submitted. Kindly remember, Company has right to hold any data relating to your use of the Services and the information that you have shared with payment gateway providers, because we believe it is important to guarantee the correct and precise working of the Services provided by DailyPe. Data gathered by payment gateway providers might be utlilized by DailyPe to decide if the services offered might be benefitted by you or not.</p>
        <Typography variant="h5" gutterBottom id="permissions">8. Permissions</Typography>
        <p>At DailyPe, we place the utmost importance on the security of your information. In order to provide our services with the highest standards of data protection, we request certain permissions from your mobile device when you engage with our application.</p>
        <ul>
          <li><strong>SMS:</strong> DailyPe can access all SMSs to assess the income, financial expenses etc only for the purpose of loan onboarding journey. The assessment is automated and the SMSs are encrypted. DailyPe does not read or store any of your personal SMS data. Also, it does not share your SMSs with any third party.</li>
          <li><strong>Contacts :</strong>  With your explicit consent, your contact list is collected to assess the risk associated with your loan application and facilitate quicker loan approval.</li>
          <li><strong>Location Data:</strong> With your explicit consent, we may intermittently collect location data during your app usage. This data serves the purpose of detecting potential fraudulent activities and enhancing security measures. It is important to note that this data is solely processed by automated systems and is not accessible by individuals.</li>
          <li><strong>Device Information:</strong> We collect specific device information, such as hardware model and software version, to ensure device authenticity and prevent unauthorized use. This includes unique identifiers like Android Device ID, aiding in the secure identification of devices and safeguarding against fraudulent activities.</li>
          <li><strong>Photos and Media Documents:</strong> We respect your privacy and adhere to strict guidelines regarding camera and photo access. When you opt to share files, only the files you expressly grant access to will be transmitted to our servers. These files are exclusively used to support authenticity of your loan application and comply with the local financial guidelines on data needed to be collected to process loans while maintaining data security and confidentiality.</li>
          <li><strong>Installed Application:</strong> DailyPe collects your installed applications on the device to enable understanding the usage of applications like VPN, gaming applications and others for fraud detection and understanding your risk profile, during the lending journey.</li>
        </ul>
        <p>Your provision of these permissions enhances the functionality and security of our application. It is crucial to reiterate that sensitive data is accessed only by our automated systems, dedicated to risk assessment and fraud detection. No human interaction with this data is permitted. </p>
        <Typography variant="h5" gutterBottom>9. Age Restrictions</Typography>
        <p>We do not collect personal information from anyone under the age of 21 or knowingly allow such persons to register with us. If we become aware that we have collected personal information from a child who is under the age of 21 then we will take necessary steps to delete that information.</p>
        <p>Note:- To enrol with us you should be 21 years old or more established and Indian Resident. Minors are entirely illegal from utilising the services of DailyPe.</p>
        <Typography variant="h5" gutterBottom id="amendments">10. Amendments to the Privacy Policy</Typography>
        <p>We may change this privacy policy from time to time. If we decide to change our policy, we will post those changes on our website. We also encourage you to review our privacy policy regularly, whenever you use the services of DailyPe we would like to inform you about how we are protecting your information. We reserve the right to modify this privacy policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you by email or by a notice on our homepage.</p>
        <Typography variant="h5" gutterBottom>11. Contact Us</Typography>
        <p>We take your complaints very seriously in order to meet the highest standards of our customers because we believe in quality service. Our job is not just to make our customers happy, but to satisfy them to the core. If you are unhappy with the way we have handled your personal information or any request that you have raised with us, you should contact us at the contact details.</p>
        <p>NeoFirst Technologies India Pvt Ltd, </p>
        NO 806, B2 ALPINE ECO APT,<br />
        DODDANEKUNDI MARATHALLI,<br />
        BANGALORE-560037

        {/* <p><h4>Grievance Redressal</h4> */}
          {/* For Grievance Redressal, please reach out to: <br/> */}
          {/* Kasa Prashanth <br /> <br />prashanth@dailype.in <br />
          1206, Royal Trade Center,<br /> Adajan, Surat 395009</p> */}

      </div>
      <Footer width={width} />
    </div>
  );
};

export default PrivacyPolicy;