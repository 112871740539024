import React, { Component } from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DataDeletionRequestForm from "./screens/DeletionRequest";
import Home from "./screens/Home";
import PrivacyPolicy from "./screens/Privacy";
import AboutUs from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import history from './history';
import Lenders from "./screens/Lenders";
import Terms from "./screens/Terms";
import Grievance from "./screens/Grievance";

export default class CustRoutes extends Component {
    render() {
        return (
            <Router history={history}>
                <Routes>
                    <Route path="/" exact element={<Home/>} />
                    <Route path="/about-us" exact element={<AboutUs/>} />
                    <Route path="/privacy" exact element={<PrivacyPolicy/>} />
                    <Route path="/deletion-request" exact element={<DataDeletionRequestForm/>} />
                    <Route path="/lenders" exact element={<Lenders/>} />
                    <Route path="/terms" exact element={<Terms/>} />
                    <Route path="/contact-us" exact element={<ContactUs/>} />
                    <Route path="/grievance" exact element={<Grievance/>} />
                </Routes>
            </Router>
        )
    }
}
