import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const LCard = ({ image, name, regNo, gstNo, cinNo, grievanceEmail, website }) => {
    const linkStyle = {
        color: 'textSecondary',  // Set the default color
        '&:hover': {
            color: 'textSecondary',  // Set the hover color to be the same as the default color
        },
    };

    return (
        <Card style={{ width: 360, padding: '16px' }}>
            <CardMedia
                component="img"
                alt="Logo"
                height="200"
                image={image}
                style={{ objectFit: 'contain' }}
            />
            <CardContent style={{ marginTop: '16px', fontFamily: 'Plus Jakarta Sans' }}>
                <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'Plus Jakarta Sans' }}>
                    {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{ fontFamily: 'Plus Jakarta Sans' }}>
                    RBI Reg No. {regNo} <br />
                    GST NO. {gstNo} <br />
                    CIN NO. {cinNo}
                </Typography>
                <br/>
                <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: '16px', fontFamily: 'Plus Jakarta Sans' , display: 'inline' }}>
                    Grievance Email: <Link href={`mailto:${grievanceEmail}`} style={{ ...linkStyle, fontFamily: 'Plus Jakarta Sans' }}>
                        {grievanceEmail}
                    </Link>
                    <br/>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: '16px', fontFamily: 'Plus Jakarta Sans' , display: 'inline' }}>
                    Website: <Link href={website} target="_blank" rel="noopener" style={{ ...linkStyle, fontFamily: 'Plus Jakarta Sans' }}>
                    {website}
                    </Link>
                </Typography>
            </CardContent>
        </Card>
    );
};

export default LCard;