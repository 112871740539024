import React, {useState, useEffect} from 'react';
import Footer from '../components/Footer'
import HeroSection from '../components/Lenders/Hero'
import Content from '../components/Lenders/Content';

const Lenders = () => {

  const [width, setWindowWidth] = useState(0);

  useEffect( () => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
   
  return (
    <div>
        <HeroSection width={width}/>
        <Content width={width}/>
        <Footer width={width}/>
    </div>
  )
}

export default Lenders