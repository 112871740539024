import React from 'react'
import {  Col } from 'antd'
import { backgroundColor3, tableHeaderColor, tableHRowColor1, tableHRowColor2 } from '../const';
import { Table } from 'antd';
import styled from 'styled-components'
import { isMobile, isSM } from '../helpers/config';
const CustomTable = styled(Table)`
    .ant-table-thead .ant-table-cell {
        background-color: ${tableHeaderColor};
    }
    .table-row-light {
        background-color:  ${tableHRowColor1};
    }
    .table-row-dark {
        background-color: ${tableHRowColor2};
    }
`;
const columns = [
  {
    title: 'Loan amount (100 days)',
    width: 222.75,
    dataIndex: 'la3m',
    key: '1',
  },
  {
    title: 'Daily instalment',
    width: 222.75,
    dataIndex: 'di1',
    key: '2',
  },
  // {
  //   title: 'Loan amount (6 Months)',
  //   width: 222.75,
  //   dataIndex: 'la6m',
  //   key: '3',
  // },
  // {
  //   title: 'Daily instalment',
  //   width: 222.75,
  //   dataIndex: 'di2',
  //   key: '4',
  // },
  
  
];
const data = [
  {
    key: '1',
    la3m: '10,000',
    di1: 110,
    // la6m: '10,000',
    // di2: 60,
  },
  {
    key: '2',
    la3m: '25,000',
    di1: 275,
    // la6m: '25,000',
    // di2: 150,
  },
  {
    key: '3',
    la3m: '50,000',
    di1: 550,
    // la6m: '50,000',
    // di2: 300,
  },
  {
    key: '4',
    la3m: '1,00,000',
    di1: 1100,
    // la6m: '1,00,000', 
    // di2: 600,
  },
];
const DailyInstallments = (props) => {
    const cssStyle = {
        container : {
            display:'flex',
            flexDirection:'column',
            padding:'64px 20px',
            backgroundColor: backgroundColor3,
            alignItems:'center',
            justifyContent:'center',
        },
        heading:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width)?'18px': isSM(props.width)?'28px': '32px',
            fontWeight: '700',
            lineHeight: '48px',
            letterSpacing: "0em",
            marginBottom:'12px',
            color:'rgba(29, 29, 29, 0.7)',
        },
        headingSpan:{
            color:'rgba(29, 29, 29)',
            marginRight:'6px',
            marginLeft:'6px',
        },
       
        normalText:{
            marginBottom:'24px',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width)?'12px': isSM(props.width)?'14px': '16px',
            fontWeight: '500',
            lineHeight: '24px',
            textAlign:'center',
            letterSpacing: "0em",
            color:'rgba(29, 29, 29)',
        },
        
        padd:{
          padding:'0px 20px'
        }
    };
  return (
    <Col style={cssStyle.container}>
        <div style={cssStyle.heading}>
            How <span style={cssStyle.headingSpan}>daily installment</span> works?
        </div>
        <div style={cssStyle.normalText}>The loan amount will be recovered daily from the person according to the time{!isMobile(props.width)&& <br/>} & amount of loan he/she has.</div>
        <CustomTable
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                  x: 400,
              }}
          />
    </Col>
  )
}

export default DailyInstallments