
import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Footer from "../components/Footer";
import "../styles/contact.css"

const ContactUs = () => {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    const cssStyle = {
        logo: {
            marginTop: "8px",
            height: "32px",
            width: "108px",
        },
        logo2: {
            height: "28px",
            width: "32px",
        },
        logoContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "24px",
        },
        container: {
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "16px",
            marginBottom: "16px",
        },
    };

    return (
        <div className="contact-us-page">
            <div className="contact-us">
                <Row style={cssStyle.logoContainer}>
                    <img src="/images/dp-logo.png" alt="logo" style={cssStyle.logo2} />
                    <div style={{ width: "8px" }} />
                    <img src="/images/text-logo.png" alt="logo" style={cssStyle.logo} />
                </Row>

                <h1>Contact Us</h1>

                <Row justify="center" gutter={16}>

                    <Col xs={24} md={12}>
                        <div style={cssStyle.container}>
                            <h2>Address</h2>
                            <p>
                                NeoFirst Technologies India Pvt Ltd,
                                <br />
                                <br />
                                NO 806, B2 ALPINE ECO APT,
                                <br />
                                DODDANEKUNDI MARATHALLI,
                                <br />
                                BANGALORE-560037
                            </p>
                        </div>
                    </Col>

                    {/* Grievance Redressal Container */}
                    <Col xs={24} md={12}>
                        <div style={cssStyle.container}>
                            <h2>Grievance Redressal</h2>
                            <p>
                                Kasa Prashanth
                                <br />
                                <br />
                                prashanth@dailype.in
                                <br />
                                1206, Royal Trade Center,
                                <br />
                                Adajan, Surat 395009
                            </p>
                        </div>
                    </Col>
                </Row>

            </div>
            <Footer width={width} />
        </div>
    );
};

export default ContactUs;