import React, { useState, useEffect } from "react";
import { Row } from "antd";
import Footer from "../components/Footer";
import "../styles/about.css";

const AboutUs = () => {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    const cssStyle = {
        logo: {
            marginTop: "8px",
            height: "32px",
            width: "108px",
        },
        logo2: {
            height: "28px",
            width: "32px",
        },
        logoContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "24px",
        },
        container: {
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "16px",
            marginBottom: "16px",
        },
        aboutContent: {
            textAlign: "center",
            padding: "16px",
            marginBottom: "24px",
        },
    };

    return (
        <div className="about-us-page">
            <div className="about-us">
                <Row style={cssStyle.logoContainer}>
                    <img src="/images/dp-logo.png" alt="logo" style={cssStyle.logo2} />
                    <div style={{ width: "8px" }} />
                    <img src="/images/text-logo.png" alt="logo" style={cssStyle.logo} />
                </Row>

                <h1>About Us</h1>

                <div style={cssStyle.container}>
                    <div style={cssStyle.aboutContent}>
                        <p>
                            DailyPe, a venture by Neofirst Technologies Pvt Ltd, stands as a beacon for small business owners,
                            offering a revolutionary approach to financial support. Our mission is to empower SMEs to
                            achieve their dreams by providing loans that are uniquely structured for daily repayment, breaking
                            away from the conventional monthly repayment model. As a proud member of YCombinator's W23 batch,
                            DailyPe is at the forefront of innovation in lending technology.
                        </p>
                        <br/>
                        <p>
                            In collaboration with Marg Techno Projects, an NBFC accredited by the RBI, we ensure the highest standards of compliance and security in our lending practices.
                            Our dynamic team of young and energetic professionals is dedicated to managing the entire tech
                            infrastructure, propelling DailyPe to new heights.
                        </p>
                        <br/>
                        <p>
                            Founded with the vision to make financial services more accessible, transparent, and straightforward,
                            DailyPe is rapidly expanding its footprint in regions like Gujarat and Rajasthan, with plans to soon
                            cover the entirety of India.
                        </p>
                    </div>
                </div>

            </div>
            <Footer width={width} />
        </div>
    );
};

export default AboutUs;
