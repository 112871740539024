import React from 'react'
import { Row, Col, } from 'antd'
import { backgroundColor1 } from '../../const';
import { isxl, isMD, isMobile } from '../../helpers/config';
const HeroSection = (props) => {
    const cssStyle = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: isMobile(props.width) ? '32px 20px' : isxl(props.width) ? '32px 100px' : props.width > 1400 ? '32px 124px' : props.width > 1400 ? '32px 160px' : '32px 48px',
            backgroundColor: backgroundColor1,
        },
        logo: {
            marginTop: '12px',
            height: '32px',
            width: '108px',
        },
        logo2: {
            height: '32px',
            width: '32px',
        },
        lend: {
            width: isMobile(props.width) ? '260px' : isMD(props.width) ? '320px' : props.width < 1100 ? '440px' : '546px',
            height: isMobile(props.width) ? '260px' : isMD(props.width) ? '320px' : props.width < 1100 ? '440px' : '546px',
        },
        companyName: {
            fontFamily: 'Aref Ruqaa Ink',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '31px',
            letterSpacing: "0em",
        },
        hero: {
            display: 'flex',
            flexDirection: props.width < 768 ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: "center",
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        }
    };
    return (
        <Col style={cssStyle.container}>
            <a href='/'>
                <Row style={cssStyle.logoContainer}>
                    <img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo2} />
                    <div style={{ width: '8px' }} />
                    <img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} />
                </Row>
            </a>
            {props.width < 992 && <div style={{ height: props.width < 768 ? '48px' : '32px' }} />}
            <div style={{ height: '32px' }} />
        </Col>
    )
}

export default HeroSection