import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import MaterialDPTheme from '../themes/MaterialDPTheme';

const Grievance = () => {

    const cssStyle = {
        logo: {
            marginTop: "8px",
            height: "32px",
            width: "108px",
        },
        logo2: {
            height: "28px",
            width: "32px",
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: '24px',
        }
    };
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    const lendingPartners = [
        { name: "Marg Techno Projects Ltd", link: "Click Here" },
        { name: "The Surat Peoples Co-operative Bank Ltd", link: "Click Here" },
    ];

    return (
        <ThemeProvider theme={createTheme({
            ...MaterialDPTheme,
            palette: {
                background: {
                    default: '#E1EBF6',
                },
            },
        })}>
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Box sx={{ margin: '32px' }}>
                        <Row style={cssStyle.logoContainer}>
                            <img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo2} />
                            <div style={{ width: '8px' }} />
                            <img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} />
                        </Row>

                        <Typography variant="h4" gutterBottom>
                            Grievance Redressal Policy
                        </Typography>
                        <Typography variant="body1">
                            Neofirst Technologies Pvt Ltd ("Platform") operates an SME lending platform.
                            <br />
                            This Grievance Redressal Policy is set out as a mechanism available to enable users to lodge their complaints/grievances or give their feedback/suggestions in relation to their dealings with the Platform in a firm, conclusive and timely manner.
                        </Typography>
                        <Divider style={{ margin: '24px 0px' }} />
                        <Typography variant="h5" gutterBottom>
                            Filing a Complaint or Providing Feedback
                        </Typography>
                        <Typography variant="body1">
                            A complainant/feedback provider must send their grievance/suggestions via email to <a href="mailto:help@dailype.in">help@dailype.in</a> with their contact information and Platform identifier such as Mobile Number, Loan Account Number, User ID etc (if available).
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            2. Complaints/suggestions received via other media such as social media channels will also be supported via the same process. However, acknowledgement and follow up communication by the Platform will be restricted to the rules of the relevant media, availability of complainant/feedback provider etc.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            3. The Support Team must promptly identify and raise an appropriate ticket on a received grievance within 1 working day. Suggestions received must be forwarded to the relevant department(s) within 2 working days.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            4. In case of non-closure of complaint tickets within 5 working days, the said grievance shall be escalated to the Grievance Officer:
                            <br />
                            Kasa Prashanth
                            <br />
                            Email: <a href="mailto:prashanth@dailype.in">prashanth@dailype.in</a>
                            <br />
                            Address: 1206, Royal Trade Center, Adajan, Surat 395009
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            5. In case of non-closure of complaint tickets within 12 working days, the said grievance will be escalated to the CEO:
                            <br />
                            Shashwat Srikhande
                            <br />
                            Email: <a href="mailto:shashwat@dailype.in">shashwat@dailype.in</a>
                        </Typography>
                        <Typography variant="h5" gutterBottom style={{ marginTop: '32px' }}>
                            Grievance Redressal Mechanism of the Lender
222                        </Typography>
                        <TableContainer component={Paper} sx={{ maxWidth: 420 }} >
                            <Table sx={{ maxWidth: 420 }} aria-label="lending partners table">  
                                <TableBody>
                                    {lendingPartners.map((partner, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {partner.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <a href="#">{partner.link}</a>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </div>
                <Footer width={width} />
            </div></ThemeProvider>
    );
}

export default Grievance;
