export const isMobile = (width) => {
    return width<576;
}
export const isSM = (width) => {
    return width<768 && width>= 576;
}
export const isMD = (width) => {
    return width>=768 && width<992;
}
export const isLg = (width) => {
    return width>=992 && width<=1200;
}
export const isxl = (width) => {
    return width>1200;
}