import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { backgroundColor1, dailyinsColor } from '../const';
import { isxl, isMD, isMobile, isSM, isLg } from '../helpers/config';
import DownloadModal from './DownloadModal';

const HeroSection = (props) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cssStyle = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: isMobile(props.width) ? '32px 20px' : isxl(props.width) ? '32px 100px' : props.width > 1400 ? '32px 124px' : props.width > 1400 ? '32px 160px' : '32px 48px',
            backgroundColor: backgroundColor1,
        },
        playButton: {
            // color: '#004883',
            backgroundColor: '#004883',
            padding: '16px 30px',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            width: '160px',
            justifyContent: 'space-around',
            fontFamily: 'Plus Jakarta Sans',
            gap: '12px',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'left',
            margin: '32px 24px 0px 0px',
            textDecoration: 'none', 
            color: 'white' 
        },
        logo: {
            margin: '12px 0px 4px 12px',
            height: '32px',
            width: '108px',
        },
        logo2: {
            height: '28px',
            width: '32px',
        },
        lend: {
            width: isMobile(props.width) ? '260px' : isMD(props.width) ? '320px' : props.width < 1100 ? '440px' : '546px',
            height: isMobile(props.width) ? '260px' : isMD(props.width) ? '320px' : props.width < 1100 ? '440px' : '546px',
        },
        hero: {
            display: 'flex',
            flexDirection: props.width < 768 ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: "center",
        },
        heroLeft: {
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width) ? '20px' : isMD(props.width) ? '24px' : '32px',
            fontWeight: '400',
            lineHeight: isMobile(props.width) ? '30px' : isMD(props.width) ? '36px' : '48px',
            letterSpacing: "0em",
        },
        heroLeftBold: {
            fontWeight: '700',
        },
        heroLeft2: {
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMD(props.width) ? '14.5px' : '16px',
            fontWeight: '500',
            lineHeight: isMD(props.width) ? '18px' : '24px',
            letterSpacing: "0em",
            color: 'rgba(29, 29, 29, 0.7)',
        },
        heroLeft3: {
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: "0em",
            color: dailyinsColor
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        }
    };
    return (
        <Col style={cssStyle.container}>
            <Row style={cssStyle.logoContainer}>
                <img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo2} />
                <img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} />
            </Row>
            {props.width < 992 && <div style={{ height: props.width < 768 ? '60px' : '32px' }} />}
            <Row style={cssStyle.hero}>
                <Col>
                    <div style={cssStyle.heroLeft}>
                        Get daily business loan from<br /><span style={cssStyle.heroLeftBold}>₹2K</span> to <span style={cssStyle.heroLeftBold}>₹2L</span> in minutes!!
                    </div>
                    <div style={{ height: '32px' }} />
                    <div style={cssStyle.heroLeft2}>
                        Hate paying large monthly installments?<br />No worries, pay small amounts daily.
                    </div>

                    {(isMobile(props.width))&&
                    <a href='https://play.google.com/store/apps/details?id=com.dailype.dailype&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <div style={cssStyle.playButton} >
                            <img src='/images/google-play.png' alt='play store icon' style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '8px',
                            }} />
                            Download App
                        </div>
                    </a>}
                    <div style={{ height: '32px' }} />
                    {(isxl(props.width) || isMD(props.width) || isSM(props.width) || isLg(props.width))&& 
                   <a href='#'> <div style={cssStyle.playButton} onClick={handleOpen}>
                    <img src='/images/google-play.png' alt='play store icon' style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '8px',
                    }} />
                    Download App
                </div></a>}
                </Col>
                {props.width < 768 && <div style={{ height: '60px' }} />}
                <DownloadModal open={open} handleClose={handleClose} />
                <Col>
                    <img src='/images/lend.png' alt='logo' style={cssStyle.lend} />
                </Col>
            </Row>
            <div style={{ height: '32px' }} />
        </Col>
    )
}

export default HeroSection