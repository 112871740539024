import React from 'react'
import { Row, Col } from 'antd'
import { backgroundColor2} from '../../const';
import { isMD, isMobile, isxl } from '../../helpers/config';
import LCard from './Card';

const Content = (props) => {
    const cssStyle = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: isMobile(props.width) ? '64px 20px' : isMD(props.width) ? '64px 48px' : isxl(props.width) ? '64px 120px' : '64px',
            backgroundColor: backgroundColor2,
        },
        heading: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width) ? '28px' : '32px',
            fontWeight: '700',
            lineHeight: isMobile(props.width) ? '40px' : '48px',
            letterSpacing: "0em",
            marginBottom: '40px',
        },
        desc: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            textAlign: 'center',
            marginBottom: '40px',
        },
    };

    const lenders = [
        {
            image: '/images/lender/marg.jpeg',
            name: 'Marg Techno Projects Ltd',
            regNo: '01.00071',
            gstNo: '24AABCM6013F1Z0',
            cinNo: 'L69590GJ1993PLC019764',
            grievanceEmail: 'grievance@margtechno.com',
            website: 'https://margtechno.com/',
        },
        {
            image: '/images/lender/spcbl.png',
            name: 'The Surat Peoples Co-operative Bank Ltd',
            regNo: '',
            gstNo: '24AAAAT2885P2ZW',
            cinNo: '',
            grievanceEmail: 'customercare@spcbl.in',
            website: 'https://www.spcbl.in/',
        },
    ];

    return (
        <Col style={cssStyle.container}>
            <Row style={cssStyle.heading}>
                Our Lending Partners
            </Row>
            <Row style={cssStyle.desc}>
                At DailyPe, we are proud to partner with a diverse network of lenders to offer our customers a wide range of SME lending solutions.
                Our lenders are carefully selected and include RBI registered NBFCs and banks, ensuring credibility and reliability.
                We prioritize transparency and strive to provide our customers with all the necessary information about our lending partners through the borrower terms and conditions, loan agreements, and sanction letters.
                With our user-friendly app, website, and other platforms, we connect borrowers with the right lenders, making the lending process seamless and efficient.
                Trust DailyPe to connect you with reputable lenders for your SME financing needs.
            </Row>
            <Row gutter={[24, 24]} justify="center">
                {lenders.map((lender, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
                        <LCard {...lender} />
                    </Col>
                ))}
            </Row>
            {props.width < 768 && <div style={{ height: '32px' }} />}
        </Col>
    )
}

export default Content