import { ThemeProvider } from '@emotion/react';
import './App.css';
import CustRoutes from './CustRoutes';
import MaterialDPTheme from './themes/MaterialDPTheme';

function App() {
  return (
    <ThemeProvider theme={MaterialDPTheme}>
    <div className="App">
    <CustRoutes />
    </div>
    </ThemeProvider>
  );
}

export default App;
