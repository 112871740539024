import React from 'react'
import { Row, Col } from 'antd'
import { footerColor } from '../const';
import { isMobile, isSM } from '../helpers/config';

const Footer = (props) => {
    const cssStyle = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: isMobile(props.width) ? '64px 20px 30px 20px' : isSM(props.width) ? '64px 32px 30px 32px' : '64px 64px 30px 64px',
            backgroundColor: footerColor,
            backgroundImage: `url('/images/footer.svg')`
        },
        logo2: {
            height: '42px',
            width: '42px',
        },
        logo: {
            height: '32px',
            width: '108px',
        },
        logo3: {
            height: '28px',
            width: '32px',
        },
        companyName: {
            fontFamily: 'Aref Ruqaa Ink',
            fontSize: isMobile(props.width) || isSM(props.width) ? '20px' : '24px',
            fontWeight: '700',
            lineHeight: '38px',
            letterSpacing: "0em",
            color: 'white'
        },
        indiaContainer: {
            marginTop: '20px',
            fontFamily: 'Space Grotesk',
            fontSize: isMobile(props.width) || isSM(props.width) ? '16px' : '20px',
            fontWeight: '400',
            lineHeight: '32px',
            letterSpacing: "-0.01em",
            color: 'rgba(255, 255, 255, 0.75)',
        },
        follow: {
            fontFamily: 'Space Grotesk',
            fontSize: isMobile(props.width) || isSM(props.width) ? '20px' : '24px',
            fontWeight: '400',
            lineHeight: '32px',
            letterSpacing: "-0.01em",
            color: 'white',
        },
        ocontainer: {
            display: 'flex',
            flexDirection: isMobile(props.width) ? 'column' : 'row',
            justifyContent: 'space-between',
            flexGrow: 1
        },
        bcontainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexGrow: 1,
            gap: '24px'
        },
        bMobileContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            gap: '8px',
            textAlign: 'center',
        },
        pcontainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20px',
            flexGrow: 1
        },
        lastText: {
            fontFamily: 'Poppins',
            fontSize: isMobile(props.width) || isSM(props.width) ? '12px' : '16px',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: "0.5px",
            color: 'rgba(255, 255, 255, 0.75)',
        }
    };
    return (
        <Col style={cssStyle.container}>
            <Row style={cssStyle.ocontainer}>
                <Col>
                    <Row>
                        <a href="/"><img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo3} /></a>
                        <div style={{ width: '8px' }} />
                        <a href="/"><img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} /></a>
                    </Row>
                    <div style={cssStyle.indiaContainer}>Made with ❤️ in India</div>
                </Col>
                {isMobile(props.width) && <div style={{ height: '24px' }} />}
                <Col>
                    <div style={cssStyle.follow}>Follow us on</div>
                    <div style={{ height: '14px' }} />
                    <Row>
                        <a href='https://www.instagram.com/team_dailype/' target="_blank" rel="noreferrer" >
                            <img alt='instagram' src='/images/instagram.svg' style={cssStyle.logo2} />
                        </a>
                        <div style={{ width: '24px' }} />
                        <a href='https://www.linkedin.com/company/dailype/' target="_blank" rel="noreferrer" >
                            <img alt='linkedin' src='/images/linkedin.svg' style={cssStyle.logo2} />
                        </a>
                        <div style={{ width: '24px' }} />
                        <a href='https://twitter.com/Team_DailyPe' target="_blank" rel="noreferrer" >
                            <img alt='twitter' src='/images/twitter.svg' style={cssStyle.logo2} />
                        </a>
                    </Row>
                </Col>
            </Row>
            <div style={{ height: '64px' }} />
            {!isMobile(props.width) ? <Row style={cssStyle.bcontainer}>
                <div style={cssStyle.lastText}>Copyright 2022 © NeoFirst Technologies</div>
                <div style={cssStyle.lastText}>|</div>
                <a href="/privacy" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>Privacy policy</div>
                </a>
                <div style={cssStyle.lastText}><a href="/terms" style={{ textDecoration: 'none', color: 'inherit' }}>Terms of use</a></div>
                <div style={cssStyle.lastText}>|</div>
                <a href="/lenders" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>Partners</div>
                </a>
                <div style={cssStyle.lastText}>|</div>
                <a href="/grievance" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>Grievance Redressal</div>
                </a>
                <div style={cssStyle.lastText}>|</div>
                <a href="/about-us" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>About Us</div>
                </a>
                <div style={cssStyle.lastText}>|</div>
                <a href="/contact-us" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>Contact Us</div>
                </a>
            </Row> :
                <Col style={cssStyle.bMobileContainer}>
                    <div style={cssStyle.lastText}>Copyright 2022 © NeoFirst Technologies</div>
                    <Row style={cssStyle.pcontainer}>
                        <a href="/privacy" style={{ textDecoration: 'none' }}>
                            <div style={cssStyle.lastText}>Privacy policy</div>
                        </a>
                        <div style={cssStyle.lastText}><a href="/terms" style={{ textDecoration: 'none', color: 'inherit' }}>Terms of use</a></div>
                        <a href="/lenders" style={{ textDecoration: 'none' }}>
                            <div style={cssStyle.lastText}>Partners</div>
                        </a>
                <div style={cssStyle.lastText}>|</div>
                <a href="/grievance" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>Grievance Redressal</div>
                </a>
                <div style={cssStyle.lastText}>|</div>
                <a href="/about-us" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>About Us</div>
                </a>
                <a href="/contact-us" style={{ textDecoration: 'none' }}>
                    <div style={cssStyle.lastText}>Contact Us</div>
                </a>
                    </Row>
                </Col>
            }
        </Col>
    )
}

export default Footer