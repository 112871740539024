import React from 'react'
import { Row, Col } from 'antd'
import { backgroundColor2, stepColor } from '../const';
import { isMD, isMobile, isxl } from '../helpers/config';

const ApplyLoan = (props) => {
    const cssStyle = {
        container : {
            display:'flex',
            flexDirection:'column',
            padding: isMobile(props.width)?'64px 20px': isMD(props.width) ? '64px 48px': isxl(props.width)?'64px 120px': '64px',
            backgroundColor: backgroundColor2,
        },
        heading:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width) ? '28px' : '32px',
            fontWeight: '700',
            lineHeight: isMobile(props.width) ? '40px' :'48px',
            letterSpacing: "0em",
            marginBottom:'40px',
        },
        headingSpan:{
            color:'rgba(29, 29, 29, 0.7)',
            marginRight:'6px',
        },
        number:{
            marginRight: isMobile(props.width) || isMD(props.width) ? '12px':'26px',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            height: isMobile(props.width) || isMD(props.width) ? '36px' : '48px',
            width: isMobile(props.width) || isMD(props.width) ? '36px' :'48px',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width) || isMD(props.width) ? '16px' : '20px',
            fontWeight: '700',
            lineHeight: '25.2px',
            letterSpacing: "0em",
            backgroundColor: stepColor,
            borderRadius:'32px'
        },
        step:{
            marginBottom:'16px',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width) || isMD(props.width) ? '16px' : '20px',
            fontWeight: '600',
            lineHeight: '25px',
            letterSpacing: "0em",
            color:'rgba(29, 29, 29, 0.7)',
        },
        stepInfo:{
            fontFamily: 'Plus Jakarta Sans',
            fontSize: isMobile(props.width) ? '16px' : isMD(props.width) ? '20px' : '24px',
            fontWeight: '600',
            lineHeight: isMobile(props.width) ? '28px' : isMD(props.width) ? '32px' :'36px',
            letterSpacing: "0em",
            color:'rgba(29, 29, 29)',
        },
        image1:{
            height:isMobile(props.width) ? '188px' : isMD(props.width) ? '240px' : '300px',
            width:isMobile(props.width) ? '301px' : isMD(props.width) ? '384px' : '480px',
        },
        image2:{
            height:isMobile(props.width) ? '188px' :isMD(props.width) ? '240px':'303px',
            width:isMobile(props.width) ? '212.8px' :isMD(props.width) ? '272px':'343px',
        },
        image3:{
            height:isMobile(props.width) ? '188px' :isMD(props.width) ? '240px':'307px',
            width:isMobile(props.width) ? '226px' :isMD(props.width) ? '288.46px':'369px',
        },
        stepRow : {
            display:'flex',
            flexDirection: props.width<768 ? 'column' : 'row',
            alignItems:"center",
            marginBottom:'28px',
        },
        imageConatiner : {
            display:'flex',
            flexDirection:'column',
            alignItems:"end",
        },
        imageConatiner2 : {
            display:'flex',
            flexDirection:'column',
            alignItems:"center",
        },
        
    };
  return (
    <Col style={cssStyle.container}>
        <Row style={cssStyle.heading}>
            <span style={cssStyle.headingSpan}>Apply for loan in </span> 3 easy steps
        </Row>
        <Row style={cssStyle.stepRow}>
            <Col xs={24} sm ={24} md={12} lg={12} xl={12}>
                <Row  >
                    <Col style={cssStyle.number}>1</Col>
                    <Col>
                        <div style={cssStyle.step}>STEP 1</div>
                        <div style={cssStyle.stepInfo}>Provide the requested information<br/>to our agents.</div>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm ={24} md={12} lg={12} xl={12} style={cssStyle.imageConatiner}>
                <img alt='step1' src='/images/s1.png' style={cssStyle.image1}/>
            </Col>
        </Row>
        {props.width<768 && <div style={{ height:'32px'}}/>}
        <Row style={{...cssStyle.stepRow, flexDirection: props.width<768 ? 'column-reverse':'row'}}>
            <Col xs={24} sm ={24} md={12} lg={12} xl={12} >
                <img alt='step1' src='/images/s2.png' style={cssStyle.image2}/>
            </Col>
            <Col  xs={24} sm ={24} md={12} lg={12} xl={12} style={cssStyle.imageConatiner2}>
            <Row>
                <Col style={cssStyle.number}>2</Col>
                <Col>
                    <div style={cssStyle.step}>STEP 2</div>
                    <div style={cssStyle.stepInfo}>Hassle-Free Documentation</div>
                </Col>
            </Row>
            </Col>
            
        </Row>
        {props.width<768 && <div style={{ height:'32px'}}/>}
        <Row style={cssStyle.stepRow}>
            <Col  xs={24} sm ={24} md={12} lg={12} xl={12}>
            <Row>
                <Col style={cssStyle.number}>3</Col>
                <Col>
                    <div style={cssStyle.step}>STEP 3</div>
                    <div style={cssStyle.stepInfo}>Get money instantly in your<br/>bank account.</div>
                </Col>
            </Row>
            </Col>
            <Col xs={24} sm ={24} md={12} lg={12} xl={12} style={cssStyle.imageConatiner}>
                <img alt='step1' src='/images/s3.png' style={cssStyle.image3}/>
            </Col>
        </Row>
    </Col>
  )
}

export default ApplyLoan