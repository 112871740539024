import React from 'react'
import { Row } from 'antd'
import { backgroundColor1 } from '../const';
import { isMobile } from '../helpers/config';
const HeaderBanner = (props) => {


    const cssStyle = {
        container : {
            display:'flex',
            flexDirection:'row',
            padding:'16px',
            justifyContent:'center',
            alignItems:'center',
            backgroundColor: backgroundColor1,
        },
        celebration:{
            height:isMobile(props.width) ? '24px' :'32px',
            width:isMobile(props.width) ? '24px' :'32px',
        },
        yc:{
            width:  isMobile(props.width) ? '66px' : '107px',
            height:  isMobile(props.width) ? '15px' :'21px',
        },
        heading:{
            fontFamily: 'Space Grotesk',
            fontSize:  isMobile(props.width) ? '18px' : '24px',
            fontWeight: '500',
            lineHeight: '31px',
            letterSpacing: "-0.03em",
        },
        space : {width: isMobile(props.width) ? '8px' : '24px'},
        space2 : {width:'12px'}
    };
  
  return (
    <Row style={cssStyle.container}>
        <img alt='celebration' src='/images/celebration.png' style={cssStyle.celebration}/>
        <div style={cssStyle.space}/>
        <div style={cssStyle.heading}>We are backed by</div>
        <div style={cssStyle.space2}/>
        <img alt='yc' src='/images/yc.png' style={cssStyle.yc}/>
        <div style={cssStyle.space}/>
        <img alt='celebration' src='/images/celebration.png' style={cssStyle.celebration}/>

    </Row>
  )
}

export default HeaderBanner