import React, {useState, useEffect} from 'react';
import ApplyLoan from '../components/ApplyLoan'
import DailyInstallments from '../components/DailyInstallments'
import Footer from '../components/Footer'
import FooterBanner from '../components/FooterBanner'
import HeaderBanner from '../components/HeaderBanner'
import HeroSection from '../components/HeroSection'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Home = () => {

  const [width, setWindowWidth] = useState(0);

  useEffect( () => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

   
  return (
    <div>
        <HeaderBanner width={width}/>
        <HeroSection width={width}/>
        <ApplyLoan width={width}/>
        <DailyInstallments width={width}/>
        <FooterBanner width={width}/>
        <Footer width={width}/>
        <ToastContainer closeButton={false}/>
    </div>
  )
}

export default Home