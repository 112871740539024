import React, { useState } from 'react'
import { Col } from 'antd'
import { DialogContent, Button, CircularProgress } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';

import { MyDialog, BootstrapInput } from '../themes/CustomMuiComponents.js';
import encryptKeys from '../helpers/encrypt_sms.js';
import sendSMS from '../helpers/sms_service.js';

const DownloadModal = (props) => {
    const cssStyle = {
        divider: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '320px',
            margin: '16px 0px 16px 0px',
        },
        line: {
            border: '1px solid rgba(0, 0, 0, 0.2)',
            height: '0px',
            maxWidth: '120px',
            width: '100%',
        },
        orText: {
            margin: '0 10px',
            color: 'rgba(0, 0, 0, 0.4)',
            fontSize: '14px',
            fontWeight: '700',
            fontFamily: 'Plus Jakarta Sans',
            alignItems: 'center',
            textAlign: 'center',
        },
        head2Text: {
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '0px',
            textAlign: 'center',
            color: 'black',
        }
    };
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [smsSent, setSmsSent] = useState(false);

    const handlePhoneNumberChange = (event) => {
        const newPhoneNumber = event.target.value;
        setPhoneNumber(newPhoneNumber);

        // Enable the button only if the phone number is valid
        setIsButtonDisabled(newPhoneNumber.length !== 10 || isNaN(newPhoneNumber));
    };

    const handleSendSMS = async () => {
        setIsLoading(true); // Start loading
        const payload = { mob_num: phoneNumber };
        const encryptedKeys = encryptKeys(payload);
        const result = await sendSMS(encryptedKeys);
        setSmsSent(result);
        setIsLoading(false); // Stop loading
    };


    return <MyDialog open={props.open} onClose={props.handleClose}
    aria-labelledby="form-dialog-title">
        <DialogContent sx={{ borderRadius: '16px' }}>
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <img src='/images/close-btn.png' alt='Close button' width={'16px'} height={'16px'} style={{ padding: '4px', cursor: 'pointer' }}
                />
            </IconButton>
            <Col style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 48px'
            }}>
                <p style={cssStyle.head2Text}>Scan / Click Code</p>
                <p style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '0px',
                    textAlign: 'center',
                    letterSpacing: "0em",
                    color: 'grey'
                }}>You will be redirected to play store</p>
                <a href='https://play.google.com/store/apps/details?id=com.dailype.dailype'>
                    <img alt="QR Code to download app" src="/images/app_qr.png" style={{ maxHeight: '280px', maxWidth: '280px', margin: '16px 0' }} />
                </a>
                <div style={cssStyle.divider}>
                    <div style={cssStyle.line} />
                    <span style={cssStyle.orText}>OR</span>
                    <div style={cssStyle.line} />
                </div>
                {smsSent ? (<Col style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 64px'
                }}>
                    <p style={{
                        ...cssStyle.orText,
                        color: 'green',
                        }}>
                        We have sent a link via SMS to your mobile number!
                    </p>
                </Col>) : (<Col style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 64px'
                }}>
                    <p style={cssStyle.head2Text}>Get app link via SMS</p>
                    <p>Enter your mobile number</p>
                    <BootstrapInput
                        id="phone-number"
                        label="Phone Number"
                        variant="outlined"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        style={{
                            borderRadius: '8px',
                            width: '100%',
                            maxWidth: '320px',
                            marginBottom: '16px'
                        }}
                        inputProps={{
                            maxLength: 10,
                            pattern: "[0-9]*"
                        }}
                    />
                    {isLoading ? (
                        <div>
                            <CircularProgress />
                        </div>
                        ) : (<Button variant="contained" disabled={isButtonDisabled}
                        style={{
                            backgroundColor: isButtonDisabled ? "lightgrey" : "#004883",
                            color:  "white",
                            borderRadius: '8px',
                            fontFamily: 'Plus Jakarta Sans',
                            marginBottom: '16px',
                        }}
                            onClick={handleSendSMS}>
                        Send
                    </Button>)}
                </Col>)}
            </Col>
        </DialogContent>
    </MyDialog>
}

export default DownloadModal;