import { toast } from 'react-toastify';
async function sendSMS(encryptedData) {
    const payload = {
        data: encryptedData,
    };
    const url = 'https://notification-dev.dailype.in/send_app_link_sms';
    const options = {
        method: 'POST',
        headers: { 'content-type': 'text/plain' },
        body: JSON.stringify(payload)
    };
    try {
        return await fetch(url, options).then(response => {
            if (response.status === 200) {
                return true;
            } else {
                if (response.status === 429) {
                    toast.error('Too many requests. Try again tomorrow');
                } else if (response.status === 500) {
                    toast.error('Internal server error. Please try later');
                }
                else {
                    toast.error('Error sending SMS. Please try later');
                    return false;
                }
            }
        })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error sending SMS. Please try later');
                return false;
            });;
    } catch (error) {
        console.error(error);
    }
    return false;
}

export default sendSMS;