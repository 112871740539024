import React, { useState, useEffect } from 'react';
import { Row } from 'antd'
import { Typography } from '@mui/material';
import Footer from '../components/Footer'
import "../styles/privacy.css"

const Terms = () => {

    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };


    const cssStyle = {
        logo: {
            marginTop: '12px',
            height: '32px',
            width: '108px',
        },
        logo2: {
            height: '32px',
            width: '32px',
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: '24px',
        }
    };

    return (
        <div >
            <div className="privacy-policy-terms">
                <Row style={cssStyle.logoContainer}>
                    <img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo2} />
                    <div style={{ width: '8px' }} />
                    <img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} />
                </Row>

                <Typography variant="h4" gutterBottom>
                    Terms of Use
                </Typography>
                <p>Updated on: 2023-06-26</p>
                <p>Welcome to <a href="https://dailype.in">https://dailype.in</a> (the "Site") operated by NeoFirst Technologies India Pvt Ltd ("DailyPe"). NeoFirst Technologies (DailyPe) is engaged in merchant acquisition / tie-ups, development of credit gateway technology, assessing the creditworthiness of the customers. NeoFirst Technologies uses its proprietary software, algorithm, and credit marketplace platform for provisioning instant consumer loans through our financing partners. However, the entire credit assessment, KYC processing, credit approval, financing of consumer loans for the purchase of goods and services from online and offline channels is taken care by RBI-registered NBFCs tied up with DailyPe. The process of collection of repayments and EMIs is undertaken by DailyPe and NBFC partners through registered intermediaries.</p>

                <p>Before you may use the Site, you must read and accept all of the terms and conditions in and linked to, the <strong>Terms of Use (ToU)</strong> and the linked <strong>Privacy Policy</strong>. We reserve the right at any time, at our sole discretion, to change or otherwise modify the Terms of Use without prior notice. The headings contained in this agreement are for reference purposes only. All individuals (the "customers" or "users") visiting the website <a href="https://dailype.in">https://dailype.in</a> are required to read & understand the Terms of Use prior to proceeding to use the Website. The "customers" or "users" using this site by implication, means that they have gone through and agreed and abide by the Disclaimer, TOU and Privacy Policy.</p>

                <Typography variant="h5" gutterBottom>
                    Terms and conditions for Application for Loan:

                </Typography>
                <p>The user hereby accepts the applicable schedule of charges, fees, commissions including the key facts informed to them by DailyPe and as more particularly mentioned in the "Schedule of Charges" of this policy.</p>

                <p>That Goods & Services tax and/or such other tax is applicable and will be charged in connection with the loan.</p>

                <p>That no discount or free gift or any other commitment whatsoever is given to customers by DailyPe or any of its authorized representative(s) other than what is documented herein.</p>

                <p>DailyPe shall not process incomplete/defective application form, for which if any loss or delay is caused to customers, customers will not hold DailyPe liable for such loss or delay.</p>

                <p>That submission of loan application to DailyPe does not imply automatic approval and the Financer will decide the quantum of the loan at its sole and absolute discretion. The Financer in its sole and absolute discretion may either sanction or reject the application for granting the loan.</p>

                <p>That the Financer shall have the right to make disclosure of any information relating to me/us including personal information, details in relation to Loan, defaults, security, etc. to the Credit Information Bureau of India (CIBIL) and/or any other governmental/regulatory/statutory or private agency/entity, credit bureau, RBI, the Financer's other branches/subsidiaries/affiliates/rating agencies, service providers, other banks/financial institutions, any third parties, any assignees/potential assignees or transferees, who may need, process and publish the information in such manner and through such medium as it may be deemed necessary by the publisher/ Company/ RBI, including publishing the name as part of wilful defaulter's list from time to time, as also use for KYC information verification, credit risk analysis, or for other related purposes.</p>

                <p>The Financer reserves its right to reject the loan application and retain the loan application form along with the photograph, information and documents.</p>

                <p>In respect of any delayed payments, without prejudice to all other rights of the Financer under these Terms & Conditions: The Financer shall be entitled to recover Penal Charges me/us described in the Schedule of Charges.</p>

                <p>That I/ We shall furnish any additional documents as and when required by the Financer.</p>

                <p>That there is no impediment or restriction (whether legal or judicial) against customers and/or their asset filed/reported by any other bank/financer.</p>

                <p>That the funds shall be used for the purpose for which loan has been applied and will not be used for speculative or antisocial purpose.</p>

                <p>That the information furnished by customers is true and accurate.</p>

            </div>
            <Footer width={width} />
        </div>
    );
};

export default Terms;