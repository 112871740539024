import CryptoJS from 'crypto-js';


function encryptKeys(payload) {
  const secretKey = '6fXLj3R9idbv9WhR';
  const iv = CryptoJS.lib.WordArray.random(128 / 8); // 16 bytes for AES
  const plaintext = JSON.stringify(payload);

  // Encrypt
  const encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });

  // Properly concatenate IV and ciphertext before encoding
  const ivAndCiphertext = CryptoJS.lib.WordArray.create(iv.words.concat(encrypted.ciphertext.words));
  const encryptedKeys = CryptoJS.enc.Base64.stringify(ivAndCiphertext);

  return encryptedKeys;
}


// Export the function to make it accessible in other files
export default encryptKeys;