import React, { useState , useEffect } from 'react';
import axios from 'axios';
import { Row } from 'antd'

import { toast } from 'react-toastify';
import Footer from '../components/Footer'

const cssStyle = {
    logo: {
        marginTop: '12px',
        height: '32px',
        width: '108px',
    },
    logo2: {
        height: '32px',
        width: '32px',
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '24px',
    }
};

const useStyles = {
    body: {
        backgroundColor: '#E1EBF6',
        height: '68vh',
        padding: '32px',

    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    input: {
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        marginBottom: '16px',
        width: '360px',
        boxSizing: 'border-box',
        textAlign: 'left',
    },
    button: {
        padding: '8px 16px',
        backgroundColor: '#2196f3',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#f5f5f5',
        padding: '16px',
        textAlign: 'center',
    },
};

const DataDeletionRequestForm = () => {

  const [width, setWindowWidth] = useState(0);

  useEffect( () => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [loanAccountNumber, setLoanAccountNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = {
            Name: name,
            Mobile: mobileNumber,
            "Loan Account Num (optional)": loanAccountNumber,
        };

            const response = await axios.post(
                'https://sheetdb.io/api/v1/uhmhhryrqz8l2',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log(response.data);
            toast.success('Data deletion request submitted successfully!');
            setIsSuccess(true);

        setIsLoading(false);
    };

    return (
        <div>
            <div style={useStyles.body}>

            <Row style={cssStyle.logoContainer}>
                <img src='/images/dp-logo.png' alt='logo' style={cssStyle.logo2} />
                <div style={{ width: '8px' }} />
                <img src='/images/text-logo.png' alt='logo' style={cssStyle.logo} />
            </Row>

            <form style={useStyles.form} onSubmit={handleSubmit}>
                <h2 style={{ marginBottom: '16px' }}>Data Deletion Request</h2>
                <label style={useStyles.label}>Name</label>
                <input
                    style={useStyles.input}
                    type="text"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                />
                <label style={useStyles.label}>Mobile Number</label>
                <input
                    style={useStyles.input}
                    type="tel"
                    value={mobileNumber}
                    onChange={(event) => setMobileNumber(event.target.value)}
                    required
                />
                <label style={useStyles.label}>Loan Account Number (Optional)</label>
                <input
                    style={useStyles.input}
                    type="text"
                    value={loanAccountNumber}
                    onChange={(event) => setLoanAccountNumber(event.target.value)}
                />
                {isLoading ? (
                    <div>Loading...</div>
                ) : isSuccess ? (
                    <div>We received your request. We'll notify you soon.</div>
                ) : (
                    <button style={useStyles.button} type="submit">
                        Submit
                    </button>
                )}
            </form>
        </div>
        <Footer width={width}/>
        </div>
    );
};

export default DataDeletionRequestForm;
