import { createTheme } from '@mui/material/styles';

const MaterialDPTheme = createTheme({
  palette: {
    primary: {
      main: "#004883",
    }
  },
  typography: {
    h4: {
      fontSize: '28px',
      fontFamily: '"Plus Jakarta Sans"',
      fontWeight: 700,
      marginBottom: '16px',
    },
    h5: {
      fontSize: '20px',
      fontFamily: '"Plus Jakarta Sans"',
      fontWeight: 700,
      marginBottom: '16px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      marginBottom: '8px',
    }
  },
});

export default MaterialDPTheme;
