import React from 'react'
import { Row } from 'antd'
import { backgroundColor1 } from '../const';
import { isMD, isMobile, isSM } from '../helpers/config';
const FooterBanner = (props) => {
    const cssStyle = {
        container : {
            display:'flex',
            flexDirection:'row',
            padding:isMobile(props.width) ? '12px 8px':'16px',
            justifyContent:'center',
            alignItems:'center',
            backgroundColor: backgroundColor1,
        },
        celebration:{
            height:isMobile(props.width) ? '24px': '48px',
            width:isMobile(props.width) ? '24px':'48px',
        },
        yc:{
            width:isMobile(props.width) ? '70px': isSM(props.width)?'160px': isMD(props.width)?'200px': '278px',
            height:isMobile(props.width) ? '15px':isSM(props.width)?'32px':isMD(props.width)?'40px':'56px',
        },
        heading:{
            fontFamily: 'Space Grotesk',
            fontSize:isMobile(props.width) ? '18px': isSM(props.width)?'24px':isMD(props.width)?'32px':'48px',
            fontWeight: '500',
            lineHeight:isMobile(props.width) ? '40px': '61px',
            letterSpacing: "-0.02em",
        },
        space:{
            width:isMobile(props.width) ? '8px':isSM(props.width)?'16px': isMD(props.width)?'32px': '64px'
        },
        space2:{
            width:isMobile(props.width) ? '8px':isSM(props.width)?'16px': isMD(props.width)?'16px':'24px'
        },
    };
  
  return (
    <Row style={cssStyle.container}>
        <img alt='celebration' src='/images/celebration.png' style={cssStyle.celebration}/>
        <div style={cssStyle.space}/>
        <div style={cssStyle.heading}>We are backed by</div>
        <div style={cssStyle.space2}/>
        <img alt='yc' src='/images/yc.png' style={cssStyle.yc}/>
        <div style={cssStyle.space}/>
        <img alt='celebration' src='/images/celebration.png' style={cssStyle.celebration}/>

    </Row>
  )
}

export default FooterBanner