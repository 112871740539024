export const backgroundColor1 = "#FAE2CC";
export const backgroundColor2 = "#FAFAFE";
export const backgroundColor3 = "#EBF1FF";
export const dailyinsColor = "#0060AC";
export const buttonColor1 = "#A4C9FF";
export const buttonColor2 = "#88C1EE";
export const stepColor = "#E1EBF6";
export const reviewCardColor = "#D4E3FF";
export const footerColor = "#001C39";
export const tableHeaderColor = "#A4C9FF";
export const tableHRowColor1 = "#D4E3FF";
export const tableHRowColor2 = "#C1DAFF";